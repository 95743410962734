import React from 'react'
import styled from 'styled-components'

import PageWrapper from '../templates/PageWrapper'

import { Link } from 'gatsby'
import { Button } from '../components/atoms'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Index = () => (
  <PageWrapper>
    <Wrapper>
      <h1>I&apos;m the homepage! ❛ᴗ❛</h1>
      <p>Check out my page below:</p>
      <Link to="/about">
        <Button>Go to About page</Button>
      </Link>
    </Wrapper>
  </PageWrapper>
)

Index.displayName = 'Index'

export default Index
